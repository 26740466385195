<template>
  <banded-section
    ref="bandedSection"
    v-loading="isLoading"
    :title="$t('general.kpis')"
    :has-left-sidebar="false"
    :is-open="authorizeBool($_permission)"
    class="DashboardKpis"
  >
    <template #tooltip>
      {{ $t('components.main_dashboard.dashboard_kpis.kpi_per_brand') }}
    </template>
    <authorize :permission="$_permission">
      <div v-if="kpiCampaigns.length" class="kpi-rows columns is-multiline">
        <div
          v-for="campaign in kpiCampaigns"
          :key="campaign.id"
          class="column is-4"
        >
          <dashboard-kpi-item :campaign="campaign" />
        </div>
      </div>
      <div v-else class="message is-danger">
        <div class="message-body">
          {{ $t('pages.performance.kpi_list.no_active_campaigns') }}
        </div>
      </div>
    </authorize>
  </banded-section>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { generateUniqueWatcher } from '@hypefactors/shared/js/utils/componentUtilities'
import DashboardKpiItem from './DashboardKpiItem'

export default {
  components: {
    DashboardKpiItem
  },

  mixins: [debounceMixin(['fetchKpis'])],

  data () {
    return {
      isLoading: false,
      kpiCampaigns: [],
      cancelToken: null,
      permission: 'kpis.list'
    }
  },

  computed: {
    ...mapGetters(['getFilter']),
    activeBrands () {
      return this.getFilter('brands')
    },
    $_permission () {
      return this.$store.getters.requestDemoData || this.permission
    }
  },

  watch: {
    activeBrands: {
      immediate: true,
      handler: generateUniqueWatcher('fetchKpis')
    }
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    fetchKpis () {
      if (!this.activeBrands || !this.authorizeBool(this.$_permission)) return

      this.cancelToken && this.cancelToken.cancel()
      this.cancelToken = this.$api.cancelToken()

      this.isLoading = true
      const payload = {
        params: {
          brands: this.activeBrands,
          include: ['markets', 'brand']
        },
        cancelToken: this.cancelToken.token
      }
      this.$api.getData('kpi-campaigns', payload)
        .then(kpis => {
          this.isLoading = false
          this.kpiCampaigns = kpis.filter(kpi => !kpi.is_completed)
          if (!this.kpiCampaigns.length) {
            this.$refs.bandedSection.toggleAccordion(false)
          }
        })
        .catch(error => {
          if (this.$api.isCancelToken(error)) return
          this.$displayRequestError(error, this.$t('errors.cannot_fetch_kpi_campaigns'))
          this.isLoading = false
        })
    }
  }
}
</script>
