import Chart from './Chart'

export default class BarChart extends Chart {
  constructor (ref, series, options) {
    super(ref, series)

    this.setOptions(this.barChartOptions(options))

    this.init()
  }

  barChartOptions ({ title, categories } = {}) {
    return {
      chart: {
        type: 'bar',
        height: 30,
        backgroundColor: 'transparent',
        margin: [0, 0, 0, 0]
      },
      title: {
        text: title
      },
      legend: {
        enabled: false
      },
      xAxis: {
        // labels: {
        //   enabled: false
        // },
        visible: false,
        categories
      },
      yAxis: {
        visible: false
        // min: 0,
        // labels: {
        //   enabled: false
        // },
        // title: {
        //   text: ''
        // }
        // gridLineColor: 'transparent'
      },
      tooltip: {
        outside: true
      },
      plotOptions: {
        series: {
          stacking: 'percent',
          pointWidth: 13
        }
      }
    }
  }
}
