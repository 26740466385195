import Chart from './Chart'

class DonutChart extends Chart {
  constructor (ref, series, options) {
    super(ref, series)
    this.setOptions(this.donutChartOptions(options))

    this.init()
  }

  donutChartOptions ({ title, subtitle, formatter, height = 300, yTitleOffset = -12, ySubTitleOffset = 10, events, colors, titleStyle, valueSuffix = '%', showInLegend = false, margin }) {
    let tooltip = {
      valueSuffix // custom formatter overrides this
    }

    if (formatter) {
      tooltip.formatter = formatter
    }

    return {
      chart: {
        type: 'pie',
        height,
        events,
        margin
      },
      title: {
        text: title,
        verticalAlign: 'middle',
        y: yTitleOffset,
        style: titleStyle
      },
      subtitle: {
        text: subtitle,
        verticalAlign: 'middle',
        y: ySubTitleOffset
      },
      tooltip,
      colors,
      legend: {
        y: 10
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          size: '100%',
          innerSize: '75%',
          dataLabels: {
            enabled: false
          },
          showInLegend
        }
      }
    }
  }
}

export default DonutChart
