/**
 * Tooltip Formatter functions for charts the charts
 */

import Highcharts from 'highcharts'
import { abbr, round } from '../../utils/numberUtils'
import { moneyAbbrFormat } from '../../utils/moneyFormatters'

export function money (value) {
  // We do not transform because we do that when we loop the data.
  return moneyAbbrFormat(value, undefined, false)
}

export const abbrFormatter = function () {
  return abbr(this.y)
}

export const moneyFormatter = function () {
  return money(this.y)
}

export const percentageFormatter = function () {
  return round(this.y * 100).toFixed(1) + '%'
}

export const moneyTimeseriesFormatter = function () {
  return `
  <span style="font-size:10px">${Highcharts.dateFormat('%A, %b %e, %Y', this.x)}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${money(this.y)}</b>
  `
}

export const timeseriesDateFormatter = function () {
  return `
  <span style="font-size:10px">${Highcharts.dateFormat('%b %e, %Y', this.x)}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${this.y}</b>
  `
}

export const abbrCategoryFormatter = function () {
  return `
  <span style="font-size:10px">${this.x}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${abbr(this.y)}</b>
  `
}

export const moneyCategoryFormatter = function () {
  return `
  <span style="font-size:10px">${this.x}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${money(this.y)}</b>
  `
}

export const abbrDonutFormatter = function () {
  return `
  <span style="font-size:10px">${this.point.name}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${abbr(this.y)}</b>
  `
}

export const moneyDonutFormatter = function () {
  return `
  <span style="font-size:10px">${this.point.name}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${money(this.y)}</b>
  `
}

export const abbrTimeseriesFormatter = function () {
  return `
  <span style="font-size:10px">${this.point.name || ''}</span>
  <br>
  <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${abbr(this.y)}</b>
  `
}
