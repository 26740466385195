<template>
  <div ref="chart" class="TopSentimentsChart is-width-full" />
</template>

<script>
import StackedBarChart from '@hypefactors/shared/js/components/charts/base/StackedBarChart'
import { lightSalmon, lightCharcoal, lightGreen } from '@hypefactors/shared/js/components/charts/DefaultChartColors'

/**
 * @module TopSentimentsChart
 */
export default {
  name: 'TopSentimentsChart',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  watch: {
    chartData: 'updateChartData'
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      this.$chart = new StackedBarChart(
        this.$refs.chart,
        this.createSeries(),
        { categories: [this.$t('general.sentiment')] }
      )
    },
    updateChartData () {
      this.$chart.update(this.createSeries())
    },
    createSeries () {
      return [{
        name: 'Negative',
        data: [this.chartData.negative.current],
        color: lightSalmon
      }, {
        name: 'Neutral',
        data: [this.chartData.neutral.current],
        color: lightCharcoal
      }, {
        name: 'Positive',
        data: [this.chartData.positive.current],
        color: lightGreen
      }]
    }
  }
}
</script>
