<script>
import { mapGetters } from 'vuex'
import TopSentimentsChart from './TopSentimentsChart'

import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

/**
 * @module DashboardTopSentimentsDataProvider
 */
export default {
  name: 'DashboardTopSentimentsDataProvider',
  components: { TopSentimentsChart },
  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedDashboardFilters', vuexModule: 'dashboard' })],
  data () {
    return {
      permission: 'performance-facts.list',
      sentimentData: []
    }
  },
  computed: {
    ...mapGetters(['getBrand'])
  },
  methods: {
    async fetchData () {
      this.sentimentData = (await MetricsService.sentimentsByBrand({
        params: this.appliedDashboardFilters,
        cancelToken: this.cancelToken.token
      })).sentiments.text
    }
  },
  render () {
    return this.$scopedSlots.default({
      sentimentData: this.sentimentData
    })
  }
}
</script>
