<template>
  <banded-section
    :title="$t('components.main_dashboard.brand_facts.title')"
  >
    <template #tooltip>
      <div class="">
        <p class="m-b-m">
          {{ $t('components.main_dashboard.brand_facts.sidebar_description_1') }}
        </p>
        <p>
          {{ $t('components.main_dashboard.brand_facts.sidebar_description_2') }}
        </p>
      </div>
    </template>
    <div class="Dashboard-BrandFacts">
      <div v-loading="brandTotalsLoading">
        <dashboard-top-sentiments-data-provider
          #default="{ sentimentData }"
          :fetch-when="fetchWhen"
        >
          <div class="brand-rows">
            <brand-row
              v-for="brand in activeDashboardBrands"
              :key="brand.id"
              :brand="brand"
              :sentiment-data="sentimentData[brand.id] || null"
            />
          </div>
        </dashboard-top-sentiments-data-provider>
      </div>
    </div>
  </banded-section>
</template>

<script>
import BrandRow from './DashboardBrandFactsBrandRow'

import { mapState } from 'vuex'
import DashboardTopSentimentsDataProvider from './DashboardTopSentimentsDataProvider'

export default {
  components: {
    DashboardTopSentimentsDataProvider,
    BrandRow
  },
  props: {
    fetchWhen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('dashboard', ['activeDashboardBrands', 'brandTotalsLoading'])
  }
}
</script>
