<template>
  <dropdown-list
    :elements="brands"
    :selected-elements="activeDashboardBrands"
    :handler="toggleBrandInActiveBrands"
    label-key="name"
  >
    <template #trigger>
      <dashboard-filters-icon
        :label="$t('components.dashboard.brands')"
        class="DashboardFiltersV2-DropdownTrigger"
        icon="fire"
      />
    </template>
  </dropdown-list>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

/**
 * @module DashboardFiltersBrandsPicker
 */
export default {
  name: 'DashboardFiltersBrandsPicker',
  computed: {
    ...mapGetters(['brands']),
    ...mapState('dashboard', [
      'activeDashboardBrands'
    ])
  },
  methods: {
    ...mapActions('dashboard', ['updateActiveDashboardBrands']),
    toggleBrandInActiveBrands (brand) {
      this.updateActiveDashboardBrands({ brand })
        .then(({ message, data }) => {
          this.$notify.success(this.$t(message, data))
        })
        .catch((e) => {
          this.$notify.warning(this.$t(e.message))
        })
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

</style>
