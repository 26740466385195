<template>
  <a href="#" class="DashboardKpiItem" @click.prevent="goToKpiItem">
    <div class="DashboardKpiItem__barWrapper is-position-relative has-background-grey-lighter">
      <div
        :style="loadingBarStyles"
        class="DashboardKpiItem__loadingBar is-overlay opacity-80"
      />
      <v-icon
        :type="barCurrentStatusIcon"
        :style="{ color: statusIconColor }"
        class="DashboardKpiItem__icon is-absolute-centered"
      />
    </div>
    <div class="DashboardKpiItem__brand has-text-weight-bold m-v-s">
      {{ campaign.brand.data.name }}
    </div>
    <div class="DashboardKpiItem__campaignName">
      {{ campaign.name }}
    </div>
  </a>
</template>

<script>
import { mapActions } from 'vuex'
import * as chartColors from '@hypefactors/shared/js/components/charts/DefaultChartColors'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import { round } from '@hypefactors/shared/js/utils/numberUtils'
import { shadeColor } from '@hypefactors/shared/js/utils/colorUtils'

const STATES = {
  positive: {
    color: chartColors.green,
    colorShade: -0.45,
    icon: 'chart-arrow-positive'
  },
  neutral: {
    color: chartColors.grey,
    colorShade: -0.55,
    icon: 'chart-arrow-neutral'
  },
  negative: {
    color: chartColors.salmon,
    colorShade: -0.45,
    icon: 'chart-arrow-negative'
  }
}

/**
 * @module DashboardKpiItem
 */
export default {
  name: 'DashboardKpiItem',
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Return the time left until the campaign ends
     * @return {number}
     */
    barTimeLeftPercentage () {
      if (this.campaign.is_completed) return 1
      const totalMinutes = differenceInMinutes(this.campaign.end_date, this.campaign.start_date)
      const minutesFromNowToEnd = differenceInMinutes(this.campaign.end_date, Date.now())
      // if negative, aka not started, set it to 0
      return Math.max(round((totalMinutes - minutesFromNowToEnd) / totalMinutes), 0)
    },
    /**
     * Return the success rate of the campaign
     * @return {number}
     */
    barSuccessRate () {
      const values = Object.values(this.campaign.totals)
        .filter(value => value.target !== null && value.target > 0) // TODO: perhaps do this on the backend
      return values.reduce((all, current) => {
        return all + current.pct
      }, 0) / values.length / 100
    },
    /**
     * Returns the current state of the bar in words
     * @return {string}
     */
    barCurrentStatusInWords () {
      if (this.barSuccessRate > 0.5) {
        return 'positive'
      } else if (this.barSuccessRate < 0.4) {
        return 'negative'
      } else {
        return 'neutral'
      }
    },
    barCurrentStatusIcon () {
      return STATES[this.barCurrentStatusInWords].icon
    },
    /**
     * Returns the color and width of the bar to be applied as styles
     * @return {{backgroundColor: *, width: string}}
     */
    loadingBarStyles () {
      return {
        width: (this.barTimeLeftPercentage * 100) + '%',
        backgroundColor: STATES[this.barCurrentStatusInWords].color
      }
    },
    statusIconColor () {
      const state = STATES[this.barCurrentStatusInWords]
      return shadeColor(state.color, state.colorShade)
    }
  },
  methods: {
    ...mapActions(['setActiveBrand']),
    async goToKpiItem () {
      await this.setActiveBrand(this.campaign.brand.data.id)
      this.$router.push({ name: 'performance.kpi' })
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.DashboardKpiItem__barWrapper {
  height: 0;
  padding: 20%;

  .DashboardKpiItem__icon {
    font-size: 10em;
    @include widescreen {
      font-size: 12em;
    }
  }
}
</style>
