<template>
  <banded-section
    ref="bandedSection"
    :title="$t('general.relations')"
    :is-open="authorizeBool($_permission)"
    :tooltip="$t('components.main_dashboard.dashboard_relations.sidebar_description')"
    class="DashboardRelations"
  >
    <div v-loading="isLoading">
      <authorize :permission="$_permission">
        <div
          v-if="relations.length"
          class="columns"
        >
          <div
            v-for="relation in relations"
            :key="relation.id"
            class="column is-3"
          >
            <dashboard-relation-item :relation="relation" />
          </div>
        </div>

        <div
          v-else
          class="message is-danger"
        >
          <div class="message-body">
            {{ $t('components.main_dashboard.dashboard_relations.no_relations') }}
          </div>
        </div>
      </authorize>
    </div>
  </banded-section>
</template>

<script>
import * as MetricsApi from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

import DashboardRelationItem from './DashboardRelationItem'

export default {
  components: {
    DashboardRelationItem
  },

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedDashboardFilters', vuexModule: 'dashboard' })],

  data () {
    return {
      permission: 'relations.discover.list',
      relations: []
    }
  },

  computed: {
    $_permission () {
      return this.$store.getters.requestDemoData || this.permission
    }
  },

  methods: {
    /**
     * Debounce called by {@link module:MetricsDataProviderMixin}
     * @return {Promise<*[]>}
     */
    fetchData () {
      return MetricsApi.topRelations({
        params: this.appliedDashboardFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.relations = response.data.data

          if (!this.relations.length) {
            this.$refs.bandedSection.toggleAccordion(false)
          }
        })
    }
  }
}
</script>
