<template>
  <banded-section
    v-loading="brandTotalsLoading"
    :title="$t('components.dashboard_share_of_voice.title')"
    class="DashboardShareOfVoice"
  >
    <div ref="chart" />
  </banded-section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DonutChart from '@hypefactors/shared/js/components/charts/base/DonutChart'
import { abbr, round } from '@hypefactors/shared/js/utils/numberUtils'

/**
 * @module DashboardShareOfVoice
 */
export default {
  name: 'DashboardShareOfVoice',
  computed: {
    ...mapState('dashboard', ['brandTotals', 'brandTotalsLoading']),
    ...mapGetters(['getBrand'])
  },
  watch: {
    'brandTotals.clippings.brands': 'updateChart'
  },
  mounted () {
    this.createChart()
  },
  methods: {
    createChart () {
      const $t = (t) => this.$t(t)
      this.$chart = new DonutChart(this.$refs.chart, this.createSeries(), {
        height: 300,
        formatter () {
          return `
            <span style="font-size:10px">${this.point.name || ''}</span>
            <br>
            <span style="color:${this.point.color}">\u25CF</span> ${this.point.series.name}: <b>${round(this.percentage)}% (${this.y}) ${$t('general.clippings')}</b>
          `
        },
        yTitleOffset: 0,
        title: abbr(this.brandTotals.clippings.totals.current),
        showInLegend: true,
        subtitle: this.$t('components.dashboard_share_of_voice.total_mentions')
      })
    },
    updateChart () {
      this.$chart.update(this.createSeries(), abbr(this.brandTotals.clippings.totals.current))
    },
    createSeries () {
      const data = Object.entries(this.brandTotals.clippings.brands).map(([brandId, data]) => ({
        y: data.current,
        name: (this.getBrand(brandId) || { name: 'Unkown brand' }).name
      }))
      return [{
        name: this.$t('components.dashboard_share_of_voice.title'),
        data
      }]
    }
  }
}
</script>
