<template>
  <data-card
    v-if="brand"
    size="medium"
    class="is-flex is-aligned-middle has-no-overflow is-position-relative"
  >
    <template #outside-body>
      <round-avatar
        v-if="brand"
        :src="brand.logo.cropped || brand.logo.original"
        :acronym-fallback="brand.name"
        size="large"
        class="floatingBrandLogo"
      />
      <top-sentiments-chart v-if="sentimentData" :chart-data="sentimentData" class="floatingSentimentsChart" />
    </template>
    <template #default>
      <div class="p-l-xl z-index-1 is-position-relative has-text-left is-width-full">
        <div v-if="brand" class="break-words">
          {{ truncate(brand.name, 45) }}
        </div>
        <h2 v-else class="title is-2 has-text-centered">
          {{ $t('general.not_available') }}
        </h2>
      </div>
    </template>
  </data-card>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'

import TopSentimentsChart from './TopSentimentsChart'

/**
 * @module SquareBrand
 * Displays the brand in a square.
 * Used on {@link module:DashboardBrandFactsBrandRow}
 */
export default {
  name: 'SquareBrand',
  components: { TopSentimentsChart },
  mixins: [TruncateMixin],
  props: {
    brand: {
      type: Object,
      default: null
    },
    sentimentData: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.floatingBrandLogo {
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: 0;
  @include rtl {
    right: -4px;
    left: auto;
  }
}

.floatingSentimentsChart {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
}

</style>
