<template>
  <div class="Dashboard-myFacts has-background-white p-a-s">
    <summary-area-chart-totals-section
      :is-fetching-timeseries="isFetchingTimeseries"
      :is-fetching-totals="brandTotalsLoading"
      :timeseries="timeseries[type]"
      :total-clippings="brandTotals.clippings.totals"
      :total-hf-value="brandTotals.hfValue.totals"
      :total-impressions="brandTotals.impressions.totals"
      :chart-tooltip-text="tooltipTexts"
      :type="type"
      @update:type="handleTypeChange"
    >
      <template #chart>
        <div class="has-background-grey-lighter m-b-s p-v-s p-h-m is-size-3 has-text-weight-bold tracking-wider">
          <help-tooltip>
            <p class="m-b-m">
              {{ tooltipTexts[type].title }}
            </p>
            <p>
              {{ tooltipTexts[type].content }}
            </p>
          </help-tooltip>
          {{ types[type] }}
        </div>
      </template>
    </summary-area-chart-totals-section>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import * as MetricsApi from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'
import SummaryAreaChartTotalsSection from '@hypefactors/shared/js/components/charts/extended/keyMetrics/SummaryAreaChartTotalsSection'

/**
 * @module DashboardMyFacts
 * Displays the Summary area chart and boxes on the Dashboard
 */
export default {
  name: 'DashboardMyFacts',

  components: {

    SummaryAreaChartTotalsSection
  },

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedDashboardFilters', vuexModule: 'dashboard' })],

  data () {
    return {
      type: 'hfValue',

      timeseries: {
        hfValue: [],
        clippings: [],
        impressions: []
      },

      isFetchingTimeseries: false,
      cancelToken: null
    }
  },

  computed: {
    ...mapState('dashboard', ['brandTotals', 'brandTotalsLoading']),

    tooltipTexts () {
      return {
        hfValue: {
          title: this.$t('components.main_dashboard.dashboard_my_facts.sidebar_hfValue_title'),
          content: this.$t('components.main_dashboard.dashboard_my_facts.sidebar_hfValue_content')
        },
        impressions: {
          title: this.$t('components.main_dashboard.dashboard_my_facts.sidebar_impressions_title'),
          content: this.$t('components.main_dashboard.dashboard_my_facts.sidebar_impressions_content')
        },
        clippings: {
          title: this.$t('components.main_dashboard.dashboard_my_facts.sidebar_clippings_title'),
          content: this.$t('components.main_dashboard.dashboard_my_facts.sidebar_clippings_content')
        }
      }
    },

    types () {
      return {
        hfValue: this.$t('general.hypefactors_value'),
        impressions: this.$t('general.impressions'),
        clippings: this.$t('general.clippings')
      }
    }
  },

  methods: {
    ...mapActions('dashboard', ['fetchBrandTotalMetrics']),
    fetchTimeseriesByType (type = this.type) {
      this.isFetchingTimeseries = true
      return MetricsApi.fetchTimeseriesByBrandAndByType(type)({
        params: this.appliedDashboardFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.timeseries[type] = this.transformData(response.data.data)
          this.isFetchingTimeseries = false
        })
        .catch((e) => {
          if (this.$api.isCancelToken(e)) return
          this.$displayRequestError(e)
          this.isFetchingTimeseries = false
        })
    },

    /**
     * Debounce called by {@link module:MetricsDataProviderMixin}
     * @return {Promise<*[]>}
     */
    fetchData () {
      return Promise.all([
        this.fetchBrandTotalMetrics(),
        this.fetchTimeseriesByType()
      ])
    },

    async handleTypeChange (type) {
      if (this.isFetchingTimeseries) return
      // TODO: Add caching so we dont refetch data over and over if filters are unchanged
      await this.fetchTimeseriesByType(type)
      this.type = type
    },

    /**
     * Transforms the series returned from the api to a format, usable in charts.
     * @param series
     * @returns {{name: string, data: [number, number]}[]}
     */
    transformData (series) {
      return Object.entries(series.series).map(([brandId, data]) => {
        return { name: series.brands[brandId], data }
      })
    }
  }
}
</script>
