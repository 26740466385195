<template>
  <a
    href="#"
    class="DashboardRelationItem is-column is-full-height"
    @click.prevent="onClick"
  >
    <div class="DashboardRelationItem__data p-a-m has-background-grey-light is-position-relative has-text-centered is-expanded">
      <round-avatar
        :src="relation.picture_url"
        :acronym-fallback="relation.preferred_name"
        size="large"
        class="m-b-m is-inline-block"
      />

      <div class="is-uppercase tracking-wider is-size-7 has-text-weight-semibold">
        {{ $t('general.hypefactors_value') }}
      </div>

      <div class="is-size-3 has-text-weight-bold">
        <div
          v-html="relation.stats.data.hypefactors_value ? $moneyAbbrFormatHtml(relation.stats.data.hypefactors_value): $t('general.not_available')"
        />
      </div>

      <div class="DashboardRelationItem__overlay is-overlay has-background-grey-darker is-column is-aligned-middle is-aligned-center">
        <table class="DashboardRelationItem__metaTable">
          <tr>
            <td class="">
              {{ $t('general.impressions') }}
            </td>
            <td class="has-text-weight-bold">
              <template v-if="relation.stats.data.impressions">
                {{ formatImpressionsValue(relation.stats.data.impressions) }}
              </template>
              <template v-else>
                {{ $t('general.not_available') }}
              </template>
            </td>
          </tr>
          <tr>
            <td class="">
              {{ $t('general.frequency') }}
            </td>
            <td class="has-text-weight-bold">
              {{ abbr(relation.stats.data.frequency) }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="has-text-weight-bold m-t-s m-b-s">
      {{ relation.preferred_name }}
    </div>
  </a>
</template>

<script>
import FormatImpressionsValueMixin from '@hypefactors/shared/js/mixins/FormatImpressionsValueMixin'
import AbbrMixin from '@hypefactors/shared/js/mixins/AbbrMixin'

export default {
  name: 'DashboardRelationItem',

  mixins: [FormatImpressionsValueMixin, AbbrMixin],

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  methods: {
    async onClick (evt) {
      await this.$store.dispatch('setActiveBrand', this.relation.brand_id)

      this.$router.push({ name: 'connect.relation.view', params: { relationId: this.relation.id } })
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.DashboardRelationItem__data {
  overflow: hidden;
  display: block;

  &:hover {
    .DashboardRelationItem__overlay {
      transform: translateY(0)
    }
  }

  .DashboardRelationItem__overlay {
    transition: .18s ease-out;
    transform: translateY(100%);
  }
}

.DashboardRelationItem__metaTable {
  td {
    font-size: $size-7;
    padding: $padding-tiny $padding-small;
  }

  td:first-of-type {
    font-weight: $weight-semibold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}
</style>
