<template>
  <div class="columns brand-row is-variable-grid is-1">
    <div class="column is-one-third">
      <square-brand
        v-loading="isSettingNewActiveBrand"
        :brand="brand"
        :sentiment-data="sentimentData"
        class="is-full-height is-cursor-pointer"
        @click.native="redirectToFacts"
      />
    </div>
    <div class="column is-flex">
      <div class="columns is-gapless is-expanded">
        <div class="column">
          <total-hypefactors-value-data-card
            v-if="hfValue"
            :total="hfValue.current"
            :percentage="hfValue.growth"
            class="is-full-height semi-separator is-position-relative"
          />
        </div>
        <div class="column">
          <total-impressions-data-card
            v-if="impressions"
            :total="impressions.current"
            :percentage="impressions.growth"
            class="is-full-height semi-separator is-position-relative"
          />
        </div>
        <div class="column">
          <total-clippings-data-card
            v-if="clippings"
            :total="clippings.current"
            :percentage="clippings.growth"
            :aplus="clippings.growth_aplus"
            :bplus="clippings.growth_bplus"
            class="is-full-height"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { totalsStatFactory } from '@hypefactors/shared/js/factories/metrics'

import SquareBrand from '@/components/dashboard/SquareBrand'
import TotalClippingsDataCard from '@hypefactors/shared/js/components/squares/TotalClippingsDataCard'
import TotalImpressionsDataCard from '@hypefactors/shared/js/components/squares/TotalImpressionsDataCard'
import TotalHypefactorsValueDataCard from '@hypefactors/shared/js/components/squares/TotalHypefactorsValueDataCard'

/**
 * @module DashboardBrandFactsBrandRow
 * Displays the brands stats in squares
 */
export default {
  components: {
    SquareBrand,
    TotalClippingsDataCard,
    TotalImpressionsDataCard,
    TotalHypefactorsValueDataCard
  },

  props: {
    brand: {
      type: Object,
      required: true
    },
    sentimentData: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      isSettingNewActiveBrand: false
    }
  },

  computed: {
    ...mapState('dashboard', ['brandTotals']),

    hfValue () {
      return this.brandTotals.hfValue.brands[this.brand.id] || totalsStatFactory()
    },

    clippings () {
      return this.brandTotals.clippings.brands[this.brand.id] || totalsStatFactory()
    },

    impressions () {
      return this.brandTotals.impressions.brands[this.brand.id] || totalsStatFactory()
    }
  },

  methods: {
    async redirectToFacts () {
      this.isSettingNewActiveBrand = true
      await this.$store.dispatch('setActiveBrand', this.brand.id)
      this.isSettingNewActiveBrand = false
      this.$router.push({ name: 'performance.facts' })
    }
  }
}
</script>
<style lang='scss'>
@import "~utils";

@include tablet {
  .semi-separator:after {
    content: '';
    position: absolute;
    top: 5%;
    bottom: 5%;
    width: 1px;
    background: $hf__color-white;
    right: 0;
  }
}
</style>
