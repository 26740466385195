<template>
  <layout name="Default">
    <div class="Dashboard">
      <template v-if="initialFiltersPrepped">
        <authorize permission="clippings.list">
          <dashboard-filters-v2 class="p-b-s">
            <template #tertiary>
              <dashboard-filters-v2-item>
                <dashboard-filters-date-range-picker />
              </dashboard-filters-v2-item>
              <dashboard-filters-v2-item>
                <dashboard-filters-brands-picker />
              </dashboard-filters-v2-item>
              <dashboard-filters-v2-item>
                <dashboard-filters-clippings-country-picker
                  ref="countryPicker"
                  fetch-on-mounted
                  :brands="brands"
                  @ready="ready = $event"
                />
              </dashboard-filters-v2-item>
            </template>
          </dashboard-filters-v2>
          <dashboard-my-facts :fetch-when="ready" />
          <dashboard-brand-facts :fetch-when="ready" />
          <dashboard-coverage :fetch-when="ready" />
          <dashboard-relations :fetch-when="ready" />
          <dashboard-kpis :fetch-when="ready" />
          <dashboard-share-of-voice />
          <template slot="fallback" slot-scope="{}">
            <upgrade-now-notice :upgrade-url="{ name: 'cx.dashboard' }" type="dashboard" />
            <fake-data-ribbon>
              <fake-data-image-placeholder type="dashboard" />
            </fake-data-ribbon>
          </template>
        </authorize>
      </template>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import DashboardKpis from '@/components/dashboard/DashboardKpis'
import DashboardMyFacts from '@/components/dashboard/DashboardMyFacts'
import DashboardCoverage from '@/components/dashboard/DashboardCoverage'
import DashboardRelations from '@/components/dashboard/DashboardRelations'
import DashboardBrandFacts from '@/components/dashboard/DashboardBrandFacts'
import DashboardShareOfVoice from '@/components/dashboard/DashboardShareOfVoice'
import DashboardFiltersDateRangePicker from '@hypefactors/shared/js/components/filters/DashboardFiltersDateRangePicker'
import DashboardFiltersBrandsPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersBrandsPicker'
import DashboardFiltersClippingsCountryPicker from '@hypefactors/shared/js/components/filters/DashboardFiltersClippingsCountryPicker'

export default {
  components: {
    DashboardShareOfVoice,
    DashboardKpis,
    DashboardMyFacts,
    DashboardCoverage,
    DashboardRelations,
    DashboardBrandFacts,
    DashboardFiltersDateRangePicker,
    DashboardFiltersClippingsCountryPicker,
    DashboardFiltersBrandsPicker
  },

  data () {
    return {
      initialFiltersPrepped: false,
      ready: false
    }
  },

  computed: {
    ...mapGetters(['filtersQueryFor', 'hasFilter', 'getFilter']),
    brands () {
      return this.getFilter('brands')
    }
  },

  async mounted () {
    await this.setInitialFilters()
    this.initialFiltersPrepped = true
  },

  beforeDestroy () {
    this.clearDashboardBrandTotals()
  },

  methods: {
    ...mapActions(['setFilter']),
    ...mapActions('dashboard', ['setPersistedDashboardBrands']),
    ...mapActions('globalFilters', ['setDefaultFiltersTimeFiltersIfNeeded']),
    ...mapMutations('dashboard', {
      clearDashboardBrandTotals: 'CLEAR_BRAND_TOTALS'
    }),

    async setInitialFilters () {
      // Check if the start and end are not set, and if they are not, we set them
      await this.setDefaultFiltersTimeFiltersIfNeeded()

      if (this.hasFilter('brands') && this.arePersistedBrandsRelevant) return

      const selectedBrands = await this.setPersistedDashboardBrands()

      return this.setFilter({
        name: 'brands',
        value: selectedBrands.map(org => org.id)
      })
    }
  }
}
</script>
