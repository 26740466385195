<template>
  <banded-section
    :title="$t('general.top_clippings')"
    :is-open="authorizeBool($_permission)"
    class="DashboardCoverage"
  >
    <template #tooltip>
      {{ $t('components.main_dashboard.dashboard_coverage.sidebar_description') }}
    </template>
    <div v-loading="isLoading">
      <authorize :permission="$_permission">
        <top-clippings :clippings="clippings" />
      </authorize>
    </div>
  </banded-section>
</template>

<script>
import TopClippings from '@hypefactors/shared/js/components/tables/TopClippings'
import * as MetricsService from '@hypefactors/shared/js/services/api/MetricsService'
import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

/**
 * @module DashboardCoverage
 */
export default {
  components: {
    TopClippings
  },

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedDashboardFilters', vuexModule: 'dashboard' })],

  data () {
    return {
      permission: 'clippings.list',
      clippings: []
    }
  },

  computed: {
    $_permission () {
      return this.$store.getters.requestDemoData || this.permission
    }
  },

  methods: {
    /**
     * Debounce called by {@link module:MetricsDataProviderMixin}
     * @return {Promise<*[]>}
     */
    fetchData () {
      return MetricsService.topClippings({
        params: this.appliedDashboardFilters,
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.clippings = response.data.data
        })
    }
  }
}
</script>
